import { Box, Button, Stack, styled, Typography } from "@mui/material";
import MyAppBar from "../../components/Navbar/NavBar";
import classes from "./style.module.scss";
import { ReactTyped } from "react-typed";
import person from "../../assets/images/person.jpg";
// import person from "../../assets/images/test/13.png";
// import { WidthFull } from "@mui/icons-material";
import "../../App.css";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router";
import { CONTACT_US_ROUTE } from "../../util/Constants";
import Paragraph from "../Typo/Paragraph";

// import sliderImage from "../../assets/logo1.png";
{
  /* <Box sx={{ margin: "30px 0 0 0" }}>
      <Button
        variant="contained"
        size="large"
        sx={{
          marginRight: "40px",
          marginBottom: { xs: "10px", sm: "0" },
          fontFamily: "Roboto",
          textTransform: "none",
          border: "1px solid transparent",

          "&:hover": {
            border: `1px solid ${theme.palette.primary.main}`,
            background: "transparent",
            color: "#fff",
          },
        }}
        onClick={() => navigate(CONTACT_US_ROUTE)}
      >
        Connect with us
      </Button> */
}

export const AnimatedBtn = styled(Button)(({ theme }) => ({
  marginRight: "40px",
  marginBottom: { xs: "10px", sm: "10" },
  fontFamily: "Roboto",
  textTransform: "none",
  border: "1px solid transparent",

  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
    background: "transparent",
    color: "#fff",
  },
}));

const Header = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mainHeading = (
    <Typography
      className="animate__animated  animate__fadeInDown"
      sx={{
        fontSize: { xs: "2rem", sm: "3rem" },
        textTransform: "uppercase",
        fontWeight: 700,
        // backgroundImage: "linear-gradient(to right,  #28b485,#ff1f08)",
        backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        color: "transparent",
        backgroundClip: "text",
        lineHeight: "1.5",
      }}
    >
      {" "}
      Welcome to
      <br />
      Shaq Global <br />
      <ReactTyped
        strings={["Consultants", "Consultants"]}
        typeSpeed={40}
        backSpeed={50}
        loop
      />
    </Typography>
  );

  const subHeading = (
    <Typography
      variant="h5"
      sx={{
        color: "#fff",
        marginBottom: "10px",
        fontFamily: "Roboto",
        fontSize: { xs: "12px", sm: "16px" },
      }}
      gutterBottom
      // className="subHeading"
    >
      Where your financial clarity is our top priority. Let's navigate the
      numbers together.
    </Typography>
    // <Paragraph sx={{ color: "white" }}>
    //   Where your financial clarity is our top priority. Let's navigate the
    //   numbers together.
    // </Paragraph>
  );

  const actionButtons = (
    <Box sx={{ margin: "30px 0 0 0" }}>
      {/* <Button
        variant="contained"
        size="large"
        sx={{
          marginRight: "40px",
          marginBottom: { xs: "10px", sm: "0" },
          fontFamily: "Roboto",
          textTransform: "none",
          border: "1px solid transparent",

          "&:hover": {
            border: `1px solid ${theme.palette.primary.main}`,
            background: "transparent",
            color: "#fff",
          },
        }}
        onClick={() => navigate(CONTACT_US_ROUTE)}
      >
        Connect with us
      </Button> */}
      <AnimatedBtn
        variant="contained"
        size="medium"
        onClick={() => navigate(CONTACT_US_ROUTE)}
      >
        Connect with us
      </AnimatedBtn>
      <a href="#services" style={{ "scroll-behavior": "smooth" }}>
        {/* <Button
          variant="outlined"
          sx={{
            fontFamily: "Roboto",
            textTransform: "lowercase",
            textTransform: "none",
          }}
          size="large"
        >
          Services offered
        </Button> */}
        <AnimatedBtn variant="contained" size="medium">
          Services offered
        </AnimatedBtn>
      </a>
    </Box>
  );

  return (
    <Stack>
      <Box className={classes.header}>
        <Box
          className={classes.header__flex}
          sx={{
            width: { xl: "70%", lg: "80%", xs: "95%" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {/* text */}
          <Box
            className="animate__animated  animate__fadeInDown"
            sx={{
              // background: "orange",
              flex: 0.8,
              margin: "auto",
            }}
          >
            {mainHeading}
            {subHeading}
            {actionButtons}
          </Box>
          {/* image */}
          <Box sx={{ flex: 1, textAlign: "center" }}>
            <img
              sx={{ width: "100% !important" }}
              className={classes.header__image}
              src={person}
              // src="https://img.freepik.com/free-vector/set-mix-math-calculation_1308-131007.jpg"
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default Header;


// src="https://img.freepik.com/premium-vector/man-with-calculator_118813-14676.jpg"
// https://img.freepik.com/premium-vector/man-with-calculator_118813-7677.jpg
// https://img.freepik.com/premium-vector/finance-department-concept_118813-7025.jpg
// https://img.freepik.com/free-vector/set-mix-math-calculation_1308-131007.jpg